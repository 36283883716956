.field {
    margin-bottom: 30px;
}

.field.has_error input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.field label p {
    font-size: 13px;
    letter-spacing: 0.15em;
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #D3E2FF;
    margin-bottom: 12px;
}

.wrapper {}

.wrapper input {
    background-color: #1C212C;
    border: 2px solid #1C212C;
    padding: 20px 30px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "Sorts Mill Goudy", serif;
    font-size: 20px;
    color: #B3CDFF;
}

.wrapper input:focus {
    border: 2px solid #ffffff;
}

.wrapper input:-moz-placeholder { 
    color: #B3CDFF;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.6;
}
.wrapper input::-moz-placeholder { 
    color: #B3CDFF;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.6;
}
.wrapper input:-ms-input-placeholder {
    color: #B3CDFF;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.6;
}
.wrapper input::-webkit-input-placeholder {
    color: #B3CDFF;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.6;
}

.error {
    text-transform: uppercase;
    background-color: #EC2727;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 33px;
    letter-spacing: 0.15em;
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
}
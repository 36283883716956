.list {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
}

.item {
    font-size: 18px;
    letter-spacing: 0.05px;
    font-family: 'Foster', sans-serif;
    font-weight: 700;
    color: #464646;
    border: 1px solid #464646;
    border-radius: 5px;
    margin-left: 6px;
    display: inline-block;
    padding: 6px 9px;
    cursor: pointer;
}

.item.active {
    color: #fff;
    border-color: #fff;
}

.item:hover {
    background-color: #fff;
    color: #000;
    border-color: #fff;
}

@media screen and (max-width: 1600px) {
    .item {
        font-size: 16px;
        padding: 5px;
    }
}
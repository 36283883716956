.button {
    border-width: 0;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #6D96E7;
    width: 100%;
    padding: 25px 20px 22px 20px;
    cursor: pointer;
    color: #13161C;
}

.button span {
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    color: inherit;
}

.button:hover {
    background-color: #fff;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 35px;
    padding-bottom: 35px;
    z-index: 300;
    transition: all 0.7s ease-in-out;
    opacity: 1;
}

.header.hidden {
    opacity: 0;
    pointer-events: none;
}

.header.hidden.searchActive {
    opacity: 1;
    pointer-events: unset;
}

.header :global(.so-row) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1660px;
}

.header .col:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header .col:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header .trigger {
    transition: all 0.3s;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 100%;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    position: relative;
    opacity: 0.7;
}

.header .trigger i {
    transform: rotate(0deg);
    transition: all 0.3s;
    transform-origin: center;
    position: absolute;
    top: 50%;
    margin-top: -1x;
    left: 11px;
    height: 1px;
    width: 26px;
    background-color: #fff;
    display: inline-block;
}

.header .trigger i:nth-child(1) {
    margin-top: -8px;
}

.header .trigger i:nth-child(3) {
    margin-top: 8px;
}

.header .trigger:hover {
    background-color: #ffffff;
    opacity: 1;
}

.header .trigger:hover i {
    background-color: #000000;
}

.header.active .trigger i:nth-child(1) {
    transform: rotate(45deg);
    top: 50%;
    margin-top: -1px;
}

.header.active .trigger i:nth-child(2) {
    display: none;
}

.header.active .trigger i:nth-child(3) {
    transform: rotate(-45deg);
    top: 50%;
    margin-top: -1px;
}

.header .mode_mobile {
    display: none;
    margin-left: 13px;
}

.header .mode_mobile span {
    font-family: 'Marisa', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    background-color: #14161F;
    border: 1px solid #ffffff;
    color: #ffffff;
    box-sizing: border-box;
    display: inline-block;
    padding: 4px 9px;
    border-radius: 6px;
    cursor: pointer;
}

.header .mode {
    margin-left: 30px;
}

.header .mode span {
    font-family: 'Marisa', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    background-color: #14161F;
    border: 1px solid #8B92AF;
    color: #8B92AF;
    box-sizing: border-box;
    display: inline-block;
    padding: 4px 9px;
    border-radius: 6px;
    cursor: pointer;
}

.header .mode span.active,
.header .mode span:hover {
    border-color: #fff;
    color: #fff;
}

.header .mode span:nth-child(1) {
    margin-right: 10px;
}

.search {
    position: relative;
    margin-right: 25px;
}

.search input {
    font-family: "Sorts Mill Goudy", serif;
    font-size: 18px;
    color: #fff;
    border-width: 0;
    background-color: transparent;
    border-bottom: 1px solid #6F7073;
    outline: none;
    padding-bottom: 6px;
    padding-top: 6px;
    box-sizing: border-box;
    width: 310px;
    padding-right: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}

.search button {
    position: absolute;
    box-sizing: border-box;
    background-color: transparent;
    width: 20px;
    height: 20px;
    border-width: 0;
    right: 5px;
    top: 50%;
    margin-top: -10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../assets/search-icon.svg');
    pointer-events: none;
}

.search_list {
    position: absolute;
    top: calc(100% + 15px);
    left: -65px;
    right: 0;
    max-height: 370px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    height: fit-content;
    background-color: #13161C;
    border: 1px solid #3F4E69;
    border-radius: 5px;
    padding-right: 0;
}

.search_list a {
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
    letter-spacing: 0.15em;
    line-height: 1.7;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    display: block;
    cursor: pointer;
    padding-left: 25px;
    padding-right: 10px;
}

.search_list a:hover {
    background-color: #1A1F29;
}

.search_list p {
    font-family: 'Marisa', sans-serif;
    font-size: 18px;
    line-height: 1.7;
    font-weight: 700;
    letter-spacing: 0.15em;
    color: #647490;
    padding-left: 30px;
    padding-right: 30px;
}

.header.mobile {
    padding-top: 20px;
    padding-bottom: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0D0E13;
    opacity: 0.7;
    z-index: 200;
}

.header.searchActive {}

@media screen and (max-width: 1600px) {
    .search input {
        width: 260px;
    }
    .search_list {
        /* max-width: 260px; */
    }
}

@media screen and (max-width: 1024px) {
    .header {
        position: absolute;
    }

    .header .col:nth-child(2) > button span {
        display: none;
    }
    .header .col:nth-child(2) > button {
        padding: 0;
        width: 44px;
        height: 44px;
        flex-shrink: 0;
    }
    .header .col:nth-child(2) > button img {
        width: 22px;
        height: 22px;
        top: 50%;
        margin-top: -11px;
        left: 50%;
        margin-left: -11px;
    }

    .search {
        margin-right: 10px;
    }
    .search input {
        display: none;
    }
    .search button {
        background-image: url('../../assets/search_2-icon.svg');
        background-size: 20px;
        border: 1px solid #fff;
        border-radius: 5px;
        box-sizing: border-box;
        width: 44px;
        height: 44px;
        opacity: 0.7;
        margin-top: -22px;
        pointer-events: unset;
    }

    .header.searchActive .col:nth-child(1) {
        display: none;
    }
    .header.searchActive .col:nth-child(2) {
        width: 100%;
    }
    .header.searchActive .search {
        width: 100%;
    }
    .header.searchActive .search button {
        background-image: url('../../assets/close-icon.svg');
    }
    .header.searchActive .search input {
        display: block;
        width: calc(100% - 70px);
        margin-right: 40px;
    }
    .header.searchActive .search_list {
        left: 0;
        right: auto;
        width: calc(100vw - 40px);
        max-width: none;
    }

    .header .mode {
        display: none;
    }
    .header .mode_mobile {
        display: block;
    }
}
.tab_head {
    font-family: 'Marisa', sans-serif;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    display: inline-block;
    position: relative;
}

.tab_head:after {
    position: absolute;
    height: 1px;
    left: 0;
    right: 2px;
    bottom: -2px;
    background-color: #fff;
    content: '';
}
.button {
    position: relative;
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 8px 15px 6px 15px;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.7;
}

.button span {
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
}

.button:hover {
    background-color: #fff;
    opacity: 1;
}

.button:hover span {
    color: #0D0E13;
}

.button.icon {
    padding-right: 45px;
}

.button.icon img {
    position: absolute;
    width: 20px;
    top: 50%;
    margin-top: -10px;
    right: 15px;
}
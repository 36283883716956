.menu {
    transition: all 0.7s ease-in-out;
    background-color: #13161C;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 900px;
    left: -950px;
    z-index: 500;
    padding-left: calc((100vw - 1660px) / 2 + 130px);
    padding-top: 25px;
}

.menu.active {
    left: 0;
}

.overlay {
    transition: all 0.3s ease-in-out;
    background-color: #0E0F11;
    position: fixed;
    opacity: 0.4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    z-index: 490;
}

.overlay.active {
    right: 0;
}

.menuSidebar {
    position: absolute;
    left: calc((100vw - 1660px) / 2 + 28px);
    bottom: 60px;
    top: 140px;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    transform: rotate(180deg);
    transform-origin: center;
}

.menuSidebar ul {
    list-style: none;
    display: flex;
    flex-direction: column;
}

.menuSidebar ul li {
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

.menuSidebar ul:nth-child(1) li {
    margin-top: 40px;
}

.menuSidebar a,
.menuSidebar span {
    font-family: 'Marisa', sans-serif;
    cursor: pointer;
    font-size: 20px;
    letter-spacing: 0.15em;
    color: #47536B;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
}

.menuSidebar a:hover,
.menuSidebar span:hover,
.menuSidebar ul li.active a,
.menuSidebar ul li.active span {
    color: #ADBFE3;
}

.menu .trigger {
    transition: all 0.3s;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 100%;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    position: absolute;
    top: 35px;
    left: calc((100vw - 1660px) / 2 + 20px);
    opacity: 0.7;
}

.menu .trigger i {
    transform: rotate(0deg);
    transition: all 0.3s;
    transform-origin: center;
    position: absolute;
    top: 50%;
    margin-top: -1x;
    left: 11px;
    height: 1px;
    width: 26px;
    background-color: #fff;
    display: inline-block;
}

.menu .trigger i:nth-child(1) {
    margin-top: -8px;
}

.menu .trigger i:nth-child(3) {
    margin-top: 8px;
}

.menu .trigger:hover {
    background-color: #ffffff;
    opacity: 1;
}

.menu .trigger:hover i {
    background-color: #000000;
}

.menu.active .trigger i:nth-child(1) {
    transform: rotate(45deg);
    top: 50%;
    margin-top: -1px;
}

.menu.active .trigger i:nth-child(2) {
    display: none;
}

.menu.active .trigger i:nth-child(3) {
    transform: rotate(-45deg);
    top: 50%;
    margin-top: -1px;
}

.tab {
    display: none;
}

.tab .tab_head {
    margin-top: 20px;
    margin-bottom: 30px;
}

.tab .copyright {
    height: 100vh;
    padding-top: 25px;
    padding-bottom: 50px;
    margin-top: -25px;
    margin-right: 20px;
}

.tab .copyright p {
    font-size: 18px;
    line-height: 1.4;
    font-family: 'Helvetica', sans-serif;
}

.tab .verse_form {
    height: 100vh;
    padding-right: 70px;
    margin-top: -25px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-right: 20px;
}

.tab .verses {
    height: 100vh;
    padding-top: 25px;
    padding-bottom: 50px;
    margin-top: -25px;
    margin-right: 20px;
    margin-left: -20px;
}

.tab .verses_head {
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tab .verses_head > span {
    margin-right: 40px;
    cursor: pointer;
}

.tab .verses_head > span:not(.active) {
    color: #717377;
}
.tab .verses_head > span:not(.active):after {
    display: none;
}

.tab .verses_head > span:hover {
    color: #fff;
}

.tab.active {
    display: block;
}

.tab_mobile_head {
    display: none;
}

.mobile .tab {
    display: block;
    border-bottom: 1px solid #2D374B;
}

.mobile .tab .content {
    display: none;
}

.mobile .tab_mobile_head {
    display: block;
    text-align: center;
    position: relative;
    font-family: 'Marisa', sans-serif;
    font-size: 20px;
    letter-spacing: 0.15em;
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.mobile .tab_mobile_head span {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    font-size: 70px;
    line-height: 1;
    font-weight: 300;
    font-family: 'Marisa', sans-serif;
    color: #fff;
    right: 20px;
    transform: translateY(-50%);
}

.mobile.menu {
    overflow: auto;
    width: 100%;
    padding-left: 0;
    padding-top: 0;
}

.mobile .trigger {
    top: 20px;
    z-index: 2;
}

.mobile .menuSidebar {
    display: none;
}

.mobile .tab.active .content {
    display: block;
}

.mobile .tab .copyright {
    height: auto;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 0;
    margin-top: 0;
    border-top: 1px solid #2D374B;
}

.mobile .tab .copyright .tab_head {
    display: none;
}

.mobile .tab .copyright p {
    font-size: 10px;
}

.mobile .tab .verse_form {
    height: auto;
    margin-right: 0;
    margin-top: 0;
    padding-top: 0;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 1px solid #2D374B;
}

.mobile .tab .verse_form .tab_head {
    display: none;
}

.mobile .tab .verses {
    margin-right: 0;
    margin-top: 0;
    margin-left: 0;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 25px;
    border-top: 1px solid #2D374B;
}

.mobile .tab .verses_head {
    justify-content: center;
    padding-left: 0;
}

.mobile .tab .verses_head > span {
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 15px;
    margin-top: 0;
    font-size: 14px;
    padding: 3px 6px 3px 8px;
    box-sizing: border-box;
    border: 1px solid #8B92AF;
    border-radius: 5px;
    opacity: 0.5;
    color: #8B92AF;
    background-color: #14181F;
}
.mobile .tab .verses_head > span:after {
    display: none;
}

.mobile .tab .verses_head > span.active {
    opacity: 1;
    border-color: #fff;
    color: #fff;
}

.mobile .external_link {
    font-size: 16px;
    font-family: 'Marisa', sans-serif;
    letter-spacing: 0.15em;
    font-weight: 700;
    color: #81BBFF;
    text-transform: uppercase;
    display: block;
    padding: 30px 0;
    text-align: center;
    border-bottom: 1px solid #2D374B;
}

@media screen and (max-width: 1660px) {
    .menu {
        padding-left: 130px;
    }

    .menu .trigger {
        left: 20px;
    }

    .menuSidebar {
        left: 30px;
    }

    .menuSidebar a,
    .menuSidebar span {
        font-size: 16px;
    }
}
.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0D0E1390;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup {
    max-width: 770px;
    width: 100%;
    background-color: #13161C;
    box-sizing: border-box;
    padding: 45px 85px 55px 85px;
    border-radius: 5px;
    border: 1px solid #3F4E69;
    position: relative;
}

@media (max-width: 600px) {
    .popup {
        padding: 20px;
        max-width: calc(100% - 40px);
    }
}
@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&display=swap');

@font-face {
  font-family: 'Foster';
  src: 
  url('../assets/fonts/Foster/Foster-Regular.woff2') format('woff2'),
  url('../assets/fonts/Foster/Foster-Regular.woff') format('woff'),
  url('../assets/fonts/Foster/Foster-Regular.ttf') format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Foster';
  src: 
  url('../assets/fonts/Foster/Foster-Bold.woff2') format('woff2'),
  url('../assets/fonts/Foster/Foster-Bold.woff') format('woff'),
  url('../assets/fonts/Foster/Foster-Bold.ttf') format('ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Marisa';
  src: 
  url('../assets/fonts/Marisa/MarisaRegular.woff2') format('woff2'),
  url('../assets/fonts/Marisa/MarisaRegular.woff') format('woff'),
  url('../assets/fonts/Marisa/MarisaRegular.ttf') format('ttf'),
  url('../assets/fonts/Marisa/Marisa.otf') format('otf');
  font-weight: 400;
}
@font-face {
  font-family: 'Marisa';
  src: 
  url('../assets/fonts/Marisa/MarisaLight.woff2') format('woff2'),
  url('../assets/fonts/Marisa/MarisaLight.woff') format('woff'),
  url('../assets/fonts/Marisa/MarisaLight.ttf') format('ttf'),
  url('../assets/fonts/Marisa/Marisa-Light.otf') format('otf');
  font-weight: 300;
}
@font-face {
  font-family: 'Marisa';
  src: 
  url('../assets/fonts/Marisa/MarisaMedium.woff2') format('woff2'),
  url('../assets/fonts/Marisa/MarisaMedium.woff') format('woff'),
  url('../assets/fonts/Marisa/MarisaMedium.ttf') format('ttf'),
  url('../assets/fonts/Marisa/Marisa-Medium.otf') format('otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Marisa';
  src: 
  url('../assets/fonts/Marisa/MarisaBold.woff2') format('woff2'),
  url('../assets/fonts/Marisa/MarisaBold.woff') format('woff'),
  url('../assets/fonts/Marisa/MarisaBold.ttf') format('ttf'),
  url('../assets/fonts/Marisa/Marisa-Bold.otf') format('otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Courier New';
  src: url('../assets/fonts/CourierNew/cour.ttf') format('ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Courier New';
  src: url('../assets/fonts/CourierNew/cour_bold.ttf') format('ttf');
  font-weight: 700;
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: "Inter", sans-serif; */
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
  background-color: #0D0E13;
}

* {
  /* font-family: "Inter", sans-serif; */
  color: #ffffff;
  font-size: 16px;
  line-height: 1.4;
}

/* 
h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
    line-height: 1.3;
}

h1 {
  font-size: 46px;
  margin-bottom: 20px;
}

h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

h4 {
  font-size: 24px;
  margin-bottom: 20px;
}

h5 {
  font-size: 18px;
  margin-bottom: 15px;
}

h6 {
  font-size: 16px;
  margin-bottom: 10px;
} */

.so-content {
  width: 100%;
  text-align: center;
}

.so-row {
  max-width: 1240px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
  text-align: left;
  position: relative;
}
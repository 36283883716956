.form h2 {
    color: #fff;
    font-size: 54px;
    font-family: "Sorts Mill Goudy", serif;
    font-style: italic;
    font-weight: 400;
    padding-top: 30px;
    padding-bottom: 5px;
}

.form h2 + p {
    font-size: 20px;
    font-family: "Sorts Mill Goudy", serif;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 35px;
}

.form > p:last-child {
    font-size: 22px;
    font-family: "Sorts Mill Goudy", serif;
    font-style: italic;
    font-weight: 400;
    text-align: center;
    padding-top: 90px;
}

.success {
    font-size: 20px;
    font-family: "Sorts Mill Goudy", serif;
    color: #fff;
    padding: 30px;
    box-sizing: border-box;
    background-color: #5bb05b;
    margin-top: 20px;
    border-radius: 5px;
}

.error {
    font-size: 20px;
    font-family: "Sorts Mill Goudy", serif;
    color: #fff;
    padding: 30px;
    box-sizing: border-box;
    background-color: #EC2727;
    margin-top: 20px;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .success, .error {
        padding: 15px;
    }
    .form h2 {
        text-align: center;
        max-width: 220px;
        margin: auto;
        line-height: 0.9;
        margin-bottom: 25px;
        padding-top: 60px;
    }
    .form h2 + p {
        text-align: center;
    }
    .form > p:last-child {
        font-size: 16px;
        padding-top: 40px;
    }
}
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0D0E13;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: opacity 0.5s ease-in-out;
}

.hidden {
    opacity: 0;
    visibility: hidden;
}

.visible {
    opacity: 1;
    visibility: visible;
}
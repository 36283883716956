.field {
    margin-bottom: 30px;
    line-height: 1;
}

.field.has_error textarea {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.field label p {
    font-size: 13px;
    letter-spacing: 0.15em;
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #D3E2FF;
    margin-bottom: 12px;
}

.field label p span {
    font-size: inherit;
    letter-spacing: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: #637088;
}

.wrapper {
    line-height: 1;
}

.wrapper textarea {
    background-color: #1C212C;
    border: 2px solid #1C212C;
    padding: 20px 30px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    height: 190px;
    resize: none;
    font-family: "Sorts Mill Goudy", serif;
    font-size: 20px;
    color: #B3CDFF;
}

.wrapper textarea:focus {
    border: 2px solid #ffffff;
}

.wrapper textarea:-moz-placeholder { 
    color: #B3CDFF;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.6;
}
.wrapper textarea::-moz-placeholder { 
    color: #B3CDFF;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.6;
}
.wrapper textarea:-ms-input-placeholder {
    color: #B3CDFF;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.6;
}
.wrapper textarea::-webkit-input-placeholder {
    color: #B3CDFF;
    font-family: "Sorts Mill Goudy", serif;
    font-weight: 400;
    font-size: 20px;
    opacity: 0.6;
}

.error {
    text-transform: uppercase;
    background-color: #EC2727;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 33px;
    letter-spacing: 0.15em;
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
    position: relative;
    top: -3px;
}
.list {}

.list li {
    position: relative;
    cursor: pointer;
    padding: 0 100px 0 18px;
    list-style-type: none;
}

.list li.active,
.list li:hover {
    background-color: #1A1F29;
}

.list li span:nth-child(1) {
    font-family: 'Marisa', sans-serif;
    font-size: 20px;
    line-height: 2.2;
    font-weight: 700;
    letter-spacing: 0.15em;
}

.list li span:nth-child(2) {
    font-family: 'Marisa', sans-serif;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.05em;
    color: #98BAFF;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    border-radius: 5px;
    background-color: #191F29;
    border: 1px solid #3F4E69;
    padding: 3px 5px;
}

@media screen and (max-width: 768px) {
    .list li span:nth-child(1) {
        font-size: 16px;
    }
    .list li span:nth-child(2) {
        font-size: 14px;
    }
}
.canon {
    position: relative;
    padding: 0 0 0 18px;
    user-select: none;
}

.canon.active,
.canon:hover {
    background-color: #1A1F29;
}

.canon h3 {
    font-family: 'Marisa', sans-serif;
    font-size: 22px;
    line-height: 1.8;
    font-weight: 700;
    letter-spacing: 0.15em;
    cursor: pointer;
}

.canon h3 span {
    display: inline-block;
    width: 14px;
    font-family: inherit;
    font-weight: 300;
    font-size: 27px;
    color: inherit;
}

.canon ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 5px;
    padding-bottom: 10px;
}

.canon ul li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    margin-right: 7px;
    margin-bottom: 7px;
    border: 1px solid #fff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 20px;
    /* letter-spacing: 0.15em; */
    font-family: 'Marisa', sans-serif;
    cursor: pointer;
}

.canon ul li:hover {
    background-color: #fff;
    color: #1A1F29;
}

.canon ul li.disabled {
    opacity: 0.2;
    pointer-events: none;
}

.canon.disabled {
    pointer-events: none;
}

.canon.disabled h3 {
    color: #26292E;
}

@media screen and (max-width: 768px) {
    .canon h3 {
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .canon h3 span {
        font-size: 22px;
    }
    .canon ul {
        padding-bottom: 15px;
    }
}
.video {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    z-index: 1;
    transition: all 0.7s ease-in-out;
    opacity: 0;
}

.video.active {
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .video {
        display: none;
    }
}
.wrapper {
    overflow: auto;
    box-sizing: border-box;
    padding-right: 20px;
}

/* Chrome, Edge and Safari */
.wrapper::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}
.wrapper::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: #232830;
    margin-top: 25px;
    margin-bottom: 25px;
}
  
.wrapper::-webkit-scrollbar-track:hover {
    background-color: #232830;
}
  
.wrapper::-webkit-scrollbar-track:active {
    background-color: #232830;
}
  
.wrapper::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #E1E1E1;
}
  
.wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #E1E1E1;
}
  
.wrapper::-webkit-scrollbar-thumb:active {
    background-color: #E1E1E1;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    z-index: 5;
}

.wrapper.fade-in .row {
    opacity: 1;
}
.wrapper.fade-out .row {
    opacity: 0;
}

.row {
    text-align: center;
    width: 100%;
    max-width: 1240px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    transition: opacity 0.5s ease-in-out;
}

.wrapper h1 {
    font-family: 'Marisa', sans-serif;
    letter-spacing: 0.15em;
    font-size: 52px;
    color: #fff;
    font-weight: 400;
    padding-bottom: 40px;
}

.verses {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.verses .col {
    width: 100%;
    max-width: 510px;
    box-sizing: border-box;
}

.verses .col h4 {
    font-family: 'Foster', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 35px;
    letter-spacing: 0.08em;
    color: #fff;
    margin-bottom: 20px;
}

.verses .col p * {
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;
}

.verses .col:nth-child(1) p {
    font-family: "Sorts Mill Goudy", sans-serif;
    /* font-size: 32px; */
    line-height: 1.4;
}

.verses .col:nth-child(2) p {
    font-family: 'Arial', sans-serif;
    /* font-size: 30px; */
    line-height: 1.45;
}

.description {
    padding-top: 70px;
}

.description * {
    font-family: "Courier New", serif;
    font-size: 22px;
    color: #ABABAB;
}

.description p {
}

.categories {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 80px;
}

.categories strong {
    font-family: 'Marisa', sans-serif;
    line-height: 1.7;
    letter-spacing: 0.15em;
    font-size: 16px;
    font-weight: 700;
    color: #9DA0AE;
    margin-right: 10px;
    margin-bottom: 10px;
}

.categories span {
    font-family: 'Marisa', sans-serif;
    line-height: 1.7;
    letter-spacing: 0.15em;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none;
    border-radius: 5px;
    opacity: 0.4;
    box-sizing: border-box;
    display: inline-block;
    padding: 3px 10px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.categories span:hover {
    opacity: 1;
}

.highlighted .verses .col:nth-child(1) p strong,
.highlighted .verses .col:nth-child(1) p strong * {
    color: #00FFF0;
}

.highlighted .verses .col:nth-child(2) p strong,
.highlighted .verses .col:nth-child(2) p strong * {
    color: #FAFF00;
}

.versions_mob {
    display: none;
}

.lost_cross_mob_button {
    display: none;
}

@media screen and (max-width: 1600px) {
    .wrapper h1 {
        font-size: 45px;
        padding-bottom: 30px;
    }
    .verses .col h4 {
        font-size: 30px;
        margin-bottom: 10px;
    }
    .description {
        padding-top: 50px;
    }
    .description * {
        font-size: 18px;
    }
    .categories {
        padding-top: 40px;
    }
    .categories strong {
        font-size: 14px;
    }
    .categories span {
        font-size: 14px;
    }
}

@media screen and (max-width: 1024px) {
    .wrapper {
        padding-top: 125px;
        padding-bottom: 125px;
    }
    .verses {
        flex-direction: column;
    }
    .verses .col {
        max-width: none;
    }
    .verses .col:nth-child(1) {
        margin-bottom: 40px;
    }
    .versions_mob {
        margin-top: 50px;
        display: block;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        overflow: auto;
    }
    .versions_mob > div {
        justify-content: center;
        width: fit-content;
        margin: auto;
    }
    .lost_cross_mob_button {
        background-color: #161920;
        border: 1px solid #33466A;
        max-width: 320px;
        width: 100%;
        margin: auto;
        display: inline-block;
        margin-top: 30px;
        border-radius: 5px;
        color: #98BAFF;
        font-size: 16px;
        font-family: 'Courier New', Courier, monospace;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
    }
}

@media screen and (max-width: 600px) {
    .wrapper h1 {
        font-size: 24px;
        padding-bottom: 30px;
    }
    .verses .col h4 {
        font-size: 20px;
        margin-bottom: 14px;
        letter-spacing: 0.08em;
    }
    .description {
        padding-top: 50px;
    }
    .description * {
        font-size: 16px;
    }
    .categories {
        flex-direction: column;
    }
    .categories strong {
        margin-right: 0;
    }
}
.field {
    margin-bottom: 18px;
}

.field:last-child {
    margin-bottom: 0;
}

.field input {
    display: none;
}

.field label {
    font-size: 16px;
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
    letter-spacing: 0.07em;
    color: #fff;
    padding-left: 75px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.field label:before {
    transition: all 0.3s ease;
    position: absolute;
    width: 56px;
    height: 26px;
    background-color: #2F3B54;
    border-radius: 14px;
    top: 50%;
    margin-top: -13px;
    left: 0;
    box-shadow: 0 1.7px 2px 0.8px rgba(0, 0, 0, 0.1) inset;
    content: '';
}

.field label:after {
    transition: all 0.3s ease;
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #4C5A8C;
    border-radius: 100%;
    top: 50%;
    margin-top: -10px;
    left: 3px;
    box-shadow: 0 0.9px 1.5px 0.3px rgba(0, 0, 0, 0.25);
    content: '';
}

.field input:checked + label:before {
    background-color: #13FBFB;
}

.field input:checked + label:after {
    background-color: #fff;
    left: 33px;
    box-shadow: 0 0.9px -1.5px 0.3px rgba(0, 0, 0, 0.25);
}
.settings {
    position: absolute;
    bottom: calc(100% + 25px);
    right: 20px;
}

.settings > img {
    width: 26px;
    height: 26px;
    cursor: pointer;
    opacity: 1;
}

.settings > img:hover {
    opacity: 0.8;
}

.settings_list {
    background-color: #13161C;
    border: 1px solid #3F4E69;
    border-radius: 5px;
    z-index: 5;
    padding: 20px;
    position: absolute;
    right: 0;
    bottom: calc(100% + 15px);
    min-width: 270px;
    display: none;
}

.settings_list.active {
    display: block;
}

@media screen and (max-width: 1024px) {
    .settings {
        display: none;
    }
}
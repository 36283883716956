.lost_item {
    margin-bottom: 10px;
}

.lost_item:last-child {
    margin-bottom: 30px;
}

.lost_item_head {
    padding: 16px 60px 16px 60px;
    border: 1px solid #3E476D;
    background-color: #171A22;
    cursor: pointer;
    position: relative;
}

.lost_item_head:before,
.lost_item_head:after {
    position: absolute;
    width: 24px;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    left: 30px;
    background-color: #62769F;
    content: '';
}

.lost_item_head:after {
    transform: rotate(90deg);
}

.lost_item_head.active:after {
    transform: rotate(0deg);
}

.lost_item_head:hover {
    background-color: #1C212B;
    border-color: #5E678B;
}

.lost_item_head h5 {
    font-family: 'Marisa', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.7;
    letter-spacing: 0.15em;
    text-align: center;
}

.lost_item_body {
    background-color: #181C24;
    border: 1px solid #3E476D;
    border-top-width: 0;
}

.lost_item_body_versions {
    border-top: 1px solid #232B3C;
    padding: 18px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.lost_item_body_versions div {
    font-size: 18px;
    letter-spacing: 0.05px;
    font-family: 'Foster', sans-serif;
    font-weight: 700;
    color: #464646;
    border: 1px solid #464646;
    border-radius: 5px;
    margin-left: 3px;
    margin-right: 3px;
    display: inline-block;
    padding: 6px 9px;
    cursor: pointer;
    opacity: 0.4;
}

.lost_item_body_versions div.active,
.lost_item_body_versions div:hover {
    opacity: 1;
}

.lost_item_body_block_item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 35px 80px 35px 80px;
    position: relative;
    position: relative;
}

.lost_item_body_block_item:after {
    position: absolute;
    left: 80px;
    right: 80px;
    bottom: 0;
    height: 1px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ccircle cx='3' cy='3' r='2' fill='white' /%3E%3C/svg%3E");
    background-size: 10px 10px;
    opacity: 0.5;
    content: '';
}
.lost_item_body_block_item:last-child:after {
    display: none;
}

.lost_item_body_block_item > div {
    max-width: 300px;
    width: 100%;
    text-align: center;
}

.lost_item_body_block_item_cross {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -20px;
}

.lost_item_body_block_item_arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -20px;
}

.lost_item_body_block_item > div p * {
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;
}

.lost_item_body_block_item > div p {
    font-family: "Sorts Mill Goudy", sans-serif;
    line-height: 1.4;
    font-size: 18px;
    margin-bottom: 15px;
}

.lost_item_body_block_item p strong,
.lost_item_body_block_item p strong * {
    color: #00FFF0;
}

.lost_item_body_block_item_ver2 > div p {
    font-family: "Arial", sans-serif;
}

.lost_item_body_block_item_ver2 p strong,
.lost_item_body_block_item_ver2 p strong * {
    color: #FAFF00;
}

.lost_item_body_block_item > div h6 {
    font-size: 18px;
    font-family: 'Marisa', sans-serif;
    font-weight: 400;
    letter-spacing: 0.15em;
}

.lost_item_body_block_item > div h6 span {
    font-size: 18px;
    font-family: 'Foster', sans-serif;
    position: relative;
    top: 2px;
}

:global(.cross_size_2) .lost_item_body_block_item > div p {
    font-size: 22px;
}

:global(.cross_size_3) .lost_item_body_block_item > div p {
    font-size: 26px;
}

@media screen and (max-width: 1024px) {
    .lost_item_head h5 {
        font-size: 16px;
    }
    .lost_item_body_block_item {
        flex-direction: column;
    }
    .lost_item_body_block_item > div {
        max-width: none;
    }
    .lost_item_body_block_item > div:nth-child(1) {
        margin-bottom: 45px;
    }
    .lost_item_body_block_item_cross,
    .lost_item_body_block_item_arrow {
        position: static;
        transform: unset;
        margin: auto;
        margin-bottom: 45px;
    }
    .lost_item_body_block_item_arrow {
        transform: rotate(90deg);
    }
}

@media screen and (max-width: 768px) {
    .lost_item_head {
        padding: 16px 30px 16px 30px;
    }
    .lost_item_head:before,
    .lost_item_head:after {
        width: 18px;
        left: 15px;
    }
    .lost_item_body_block_item {
        padding: 35px 30px;
    }
    .lost_item_body_block_item:after {
        left: 30px;
        right: 30px;
    }
}
.controlls {
    border-top: 1px solid #464646;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    transition: all 0.7s ease-in-out;
    opacity: 1;
}

.controlls.active_menu {
    transition: unset;
    z-index: 520;
}

.controlls.hidden {
    opacity: 0;
    pointer-events: none;
}

.row {
    max-width: 1660px;
    margin: auto;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.keyboard_icon {
    position: absolute;
    width: 36px;
    height: 36px;
    cursor: pointer;
    opacity: 1;
    right: 0;
    bottom: calc(100% + 27px);
    right: 70px;
}

.keyboard_icon:hover {
    opacity: 0.8;
}

.label {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: #7694B1;
    padding: 4px 10px;
    border-radius: 7px;
    background-color: #1B222E;
    display: inline-block;
    position: absolute;
    right: 130px;
    bottom: calc(100% + 31px);
}

.content {
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
    padding-bottom: 28px;
}

.content .col {
    box-sizing: border-box;
    width: 33.33%;
}

.content .col:nth-child(3) {
    text-align: right;
}

.category_mode .content .col:nth-child(1) {
    width: 40%;
}
.category_mode .content .col:nth-child(2) {
    width: 20%;
}
.category_mode .content .col:nth-child(3) {
    width: 40%;
}

.canon_verses_list_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 82px;
}

.canon_verses_list_wrapper span {
    font-family: 'Marisa', sans-serif;
    font-size: 18px;
    letter-spacing: 0.15em;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin-right: 20px;
}

.canon_verses_list {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.canon_verses_list_item {
    flex-shrink: 0;
    font-family: 'Marisa', sans-serif;
    font-size: 20px;
    letter-spacing: 0.15em;
    font-weight: 700;
    color: #464646;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding-left: 2px;
    box-sizing: border-box;
    border: 1px solid #464646;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: none;
}

.canon_verses_list_item.active,
.canon_verses_list_item:hover {
    border-color: #fff;
    color: #fff;
}

.canon_books_list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
}

.canon_books_list_name {
    font-family: 'Marisa', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.15em;
    position: relative;
    height: 40px;
    padding-left: 45px;
    padding-right: 15px;
    box-sizing: border-box;
    border: 1px solid #464646;
    border-radius: 6px;
    cursor: pointer;
    margin-left: 6px;
    margin-right: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    user-select: none;
}

.canon_books_list_name:after {
    position: absolute;
    width: 6px;
    height: 10px;
    top: 50%;
    margin-top: -5px;
    left: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../assets/arrow_2-icon.svg');
    opacity: 0.5;
    content: '';
}

.canon_books_list_name.active {
    border-color: #fff;
}
.canon_books_list_name.active:after {
    transform: rotate(-90deg);
    opacity: 1;
}

.canon_books_list_arrow {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #464646;
    border-radius: 6px;
    cursor: pointer;
}

.canon_books_list_arrow:after {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    margin-top: -5px;
    left: 50%;
    margin-left: -5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../assets/arrow-icon.svg');
    opacity: 0.5;
    content: '';
}

.canon_books_list_name:hover,
.canon_books_list_arrow:hover {
    border-color: #fff;
}

.canon_books_list_name:hover:after,
.canon_books_list_arrow:hover:after {
    opacity: 1;
}

.canon_books_list_arrow:nth-child(1) {
    transform: rotate(180deg);
}

.menu {
    position: absolute;
    bottom: calc(100% + 1px);
    background-color: #13161C;
    border: 1px solid #3F4E69;
    border-bottom-width: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    padding-right: 13px;
}

.menu > div {
    height: 100%;
}

.menu h6 {
    font-family: 'Marisa', sans-serif;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #98BAFF;
    padding-top: 25px;
    padding-left: 30px;
    padding-bottom: 15px;
}

.menu h6 + span {
    display: none;
}

.menu h6 + span + div {
    padding-bottom: 20px;
}

.menu_chapter {
    left: 60px;
    max-width: 750px;
    width: 100%;
    height: 540px;
}

.menu_category {
    max-width: 420px;
    height: 400px;
}

.menu_category ul li {
    margin-right: -20px;
    padding-right: 125px;
    padding-left: 30px;
}

.menu_category ul li span:nth-child(1) {
    font-size: 16px;
}

.menu_category ul li span:nth-child(2) {
    font-size: 14px;
}

.menu_verses {
    max-width: 370px;
    width: 100%;
    height: 500px;
    left: 200px;
}

.menu_verses > div {
    padding-right: 0;
}

.menu_verses ul a {
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
    color: #9DA6B9;
    letter-spacing: 0.15em;
    font-size: 18px;
    line-height: 1.7;
    padding-left: 30px;
    box-sizing: border-box;
    display: block;
    text-decoration: none;
    width: 100%;
}

.menu_verses ul a.active,
.menu_verses ul a:hover {
    color: #fff;
    background-color: #1A1F29;
}

.category_block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.category_navigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.category_navigation span:nth-child(2) {
    font-family: 'Marisa', sans-serif;
    letter-spacing: 0.15em;
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    color: #fff;
}

.category_navigation_arrow {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #464646;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}

.category_navigation_arrow:after {
    position: absolute;
    width: 6px;
    height: 12px;
    top: 50%;
    margin-top: -6px;
    left: 50%;
    margin-left: -2px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../assets/arrow_2-icon.svg');
    content: '';
}

.category_navigation_arrow:nth-child(1) {
    transform: rotate(180deg);
}

.category_navigation_arrow:hover {
    border-color: #fff;
}

.keyboard_popup {
    text-align: center;
}
.keyboard_popup h4 {
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.07em;
    line-height: 1.4;
    position: relative;
    color: #fff;
    display: inline-block;
    margin: auto;
    padding-left: 35px;
    margin-bottom: 30px;
}
.keyboard_popup h4:before {
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
    top: 50%;
    margin-top: -11px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../../assets/keyboard_white-icon.svg');
    content: '';
}
.keyboard_popup_line {
    width: 100%;
    height: 1px;
    background-color: #293853;
}
.keyboard_popup_close {
    position: absolute;
    /* background-color: red; */
    width: 30px;
    height: 30px;
    top: 45px;
    right: 45px;
    cursor: pointer;
}
.keyboard_popup_close:before,
.keyboard_popup_close:after {
    position: absolute;
    width: 40px;
    height: 1px;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #6B7EA3;
    content: '';
}

.keyboard_popup_close:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.keyboard_popup_close:hover:before,
.keyboard_popup_close:hover:after {
    background-color: #fff;
}

.keyboard_popup_data {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.keyboard_popup_col {
    width: 100%;
    max-width: 260px;
    box-sizing: border-box;
    text-align: left;
}

.keyboard_popup_data h6 {
    font-family: 'Marisa', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.15em;
    color: #fff;
    margin-bottom: 12px;
    margin-top: 35px;
}

.keyboard_popup_data ul {
    list-style: none;
}

.keyboard_popup_data li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Courier New', monospace;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 5px;
}

.keyboard_popup_data li span {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    display: inline-block;
    margin-left: 5px;
}

.keyboard_popup_data li strong {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #212733;
    border-radius: 5px;
    border: 1px solid #33466A;
    box-sizing: border-box;
    margin-right: 15px;
    flex-shrink: 0;
    color: #60F5FF;
    font-family: 'Courier New', monospace;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0;
    font-weight: 400;
    padding-top: 2px;
}

.keyboard_popup_data p {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    color: #6E81A7;
    margin-top: 18px;
}

.lost_cross_label {
    position: absolute;
    left: 20px;
    bottom: calc(100% + 27px);
    z-index: -1;
}

.lost_cross_label strong {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 32px;
    height: 32px;
    background-color: #212733;
    border-radius: 5px;
    border: 1px solid #33466A;
    box-sizing: border-box;
    margin-right: 15px;
    flex-shrink: 0;
    color: #60F5FF;
    font-family: 'Courier New', monospace;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0;
    font-weight: 400;
    padding-top: 2px;
}

.lost_cross_label strong:before {
    position: absolute;
    background-color: #60F5FF;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    filter: blur(10px);
    opacity: 1;
    animation: pulse 2s ease infinite;
    content: '';
}

.cross_popup > div {
    max-width: 990px;
    padding: 0;
}

.cross_popup_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 45px;
    border-bottom: 1px solid #3F4E69;
}

.cross_popup_head h4 {
    font-size: 30px;
    letter-spacing: 0.15em;
    font-weight: 400;
    font-family: 'Marisa', sans-serif;
}

.cross_popup_head div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cross_popup_head div:nth-child(2) > span:nth-child(1) {
    font-family: 'Courier New', Courier, monospace;
    display: inline-block;
    font-size: 16px;
    margin-right: 5px;
}

.cross_popup_head_close {
    transform: rotate(45deg);
    width: 38px;
    height: 38px;
    display: inline-block;
    position: relative;
    right: -20px;
    cursor: pointer;
    opacity: 1;
}

.cross_popup_head_close:hover {
    opacity: 0.6;
}

.cross_popup_head_close:before,
.cross_popup_head_close:after {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    left: 0;
    background-color: #576174;
    content: '';
}

.cross_popup_head_close:after {
    transform: rotate(90deg);
}

.cross_popup_body {
    padding: 20px 25px 20px 45px;
}

.cross_popup_body_wrapper {
    max-height: 660px;
}

.cross_popup_body_notice {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    color: #CBCBCB;
    text-align: center;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.lost_cross_label span {
    font-family: 'Courier New', monospace;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #6E81A7;
    display: inline-block;
}

.cross_popup_sizing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #121721;
    border: 1px solid #1A212E;
    top: 73px;
    width: 60px;
    padding: 10px;
    box-sizing: border-box;
    right: -61px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cross_popup_sizing > span {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 1px solid #3E476D;
    border-radius: 5px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
}

.cross_popup_sizing > span.disabled {
    opacity: 0.3;
}

.cross_popup_sizing > span:hover {
    border-color: #9CBAF7;
    background-color: #1D2533;
}

.cross_popup_sizing > span:nth-child(1) {
    margin-bottom: 15px;
    background-image: url('../../../assets/mag-glass-plus.svg');
}
.cross_popup_sizing > span:nth-child(1):hover {
    margin-bottom: 15px;
    background-image: url('../../../assets/mag-glass-plus_white.svg');
}

.cross_popup_sizing > span:nth-child(3) {
    margin-top: 15px;
    background-image: url('../../../assets/mag-glass-minus.svg');
}
.cross_popup_sizing > span:nth-child(3):hover {
    margin-top: 15px;
    background-image: url('../../../assets/mag-glass-minus_white.svg');
}

.cross_popup_sizing > div {
    width: 45px;
    transform: rotate(-90deg);
    position: relative;
}

.cross_popup_sizing > div i {
    position: absolute;
    background-color: #2C3445;
    border-radius: 15px;
    left: 4px;
    height: 10px;
    width: 2px;
    top: 50%;
    margin-top: -5px;
    z-index: -1;
}

.cross_popup_sizing > div i.active {
    background-color: #627CAE;
}

.cross_popup_sizing > div i:nth-child(2) {}

.cross_popup_sizing > div i:nth-child(3) {
    left: 50%;
    margin-left: -1px;
}

.cross_popup_sizing > div i:nth-child(4) {
    left: auto;
    right: 4px;
}

.cross_popup_sizing > div input {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: transparent;
    margin: 20px 0;
    outline: none;
}

.cross_popup_sizing > div input::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    background: #2C3445;
    border-radius: 15px;
}

.cross_popup_sizing > div input::-moz-range-track {
    width: 100%;
    height: 2px;
    background: #2C3445;
    border-radius: 15px;
}
  
.cross_popup_sizing > div input::-ms-track {
    width: 100%;
    height: 2px;
    background: #2C3445;
    border-radius: 15px;
    border: none;
    color: transparent;
}

.cross_popup_sizing > div input::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling */
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -4px;
}
  
.cross_popup_sizing > div input::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
}

.cross_popup_sizing > div input::-ms-thumb {
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
}
  
.cross_popup_sizing > div input::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #627CAE calc((var(--value) - 1) / (3 - 1) * 100%), #2C3445 0%);
}

@media screen and (max-width: 1600px) {
    .content {
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .canon_verses_list_item {
        font-size: 18px;
        width: 34px;
        height: 34px;
    }

    .canon_books_list_name {
        font-size: 18px;
        height: 34px;
    }

    .canon_books_list_arrow {
        width: 34px;
        height: 34px;
    }

    .category_navigation span:nth-child(2) {
        font-size: 16px;
    }

    .category_navigation_arrow {
        width: 34px;
        height: 34px;
    }
}

@media screen and (max-width: 1440px) {
    .cross_popup_body_wrapper {
        max-height: 520px;
    }
}

@media screen and (max-width: 1024px) {
    .cross_popup_sizing {
        display: none;
    }
    .lost_cross_label {
        display: none;
    }
    .cross_popup > div {
        height: 100%;
        border-radius: 0;
        max-width: none;
    }
    .cross_popup_head {
        display: block;
        position: relative;
        padding: 20px;
    }
    .cross_popup_head h4 {
        font-size: 20px;
        margin-bottom: 5px;
    }
    .cross_popup_head div:nth-child(2) {
        justify-content: flex-start;
    }
    .cross_popup_head div:nth-child(2) > span:nth-child(1) {
        font-size: 14px;
        color: #98BAFF;
    }
    .cross_popup_head_close {
        position: absolute;
        top: 50%;
        margin-top: -15px;
        right: 20px;
        width: 30px;
        height: 30px;
    }
    .cross_popup_head_close:before,
    .cross_popup_head_close:after {
        background-color: #fff;
    }
    .cross_popup_body {
        padding: 10px 10px 10px 15px;
    }
    .cross_popup_body_wrapper {
        max-height: calc(100vh - 120px);
        padding-right: 5px;
    }
    .cross_popup_body_notice {
        font-size: 14px;
    }
    .label {
        display: none;
    }
    .controlls {
        background-color: #090d1b;
    }
    .keyboard_icon {
        display: none;
    }
    .row {
        padding-left: 10px;
        padding-right: 10px;
    }
    .content {
        flex-direction: column-reverse;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .content .col:nth-child(3) {
        display: none;
    }
    .content .col:nth-child(2) {
        width: 100%;
    }
    .content .col:nth-child(1) {
        width: 100%;
    }

    .canon_verses_list_wrapper {
        padding-right: 0;
        margin-bottom: 10px;
    }
    .canon_verses_list_wrapper span {
        display: none;
    }
    .canon_verses_list {
        overflow: auto;
        justify-content: flex-start;
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .canon_books_list_name {
        justify-content: center;
        width: calc(100% - 12px - 60px);
    }

    .menu {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        padding-right: 0;
        border-width: 0;
        border-radius: 0;
        max-width: none !important;
        height: unset !important;
        box-sizing: border-box;
    }
    .menu > div {
        padding-right: 0;
    }
    .menu h6 {
        border-bottom: 1px solid #2D374B;
        padding-bottom: 25px;
        padding-left: 20px;
        margin-bottom: 15px;
    }
    .menu h6 + span {
        position: absolute;
        top: 23px;
        right: 20px;
        width: 20px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../../assets/close-icon.svg');
        display: inline-block;
    }

    .menu_category ul li {
        margin-right: 0;
        padding-right: 70px;
    }

    .category_mode .content .col:nth-child(1),
    .category_mode .content .col:nth-child(2) {
        width: 100%;
    }

    .category_navigation {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .category_navigation span:nth-child(2) {
        display: none;
    }

    .category_block .canon_books_list_name:nth-child(1) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        height: 28px;
        font-size: 12px;
        margin-left: 0;
        margin-right: 0;
        border-color: #434652;
        color: #9096B0;
    }
    .category_block .canon_books_list_name:nth-child(1):hover {
        color: #fff;
        border-color: #fff;
    }

    .category_block .canon_books_list_name:nth-child(2) {
        position: absolute;
        top: 10px;
        left: 44px;
        right: 44px;
        width: auto;
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
    }
    .category_block .canon_books_list_name:nth-child(2):after {
        display: none;
    }

}